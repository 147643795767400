
export default {
  props: {
    primary: {
      type: String,
      default: '#9C242D'
    },
    secondary: {
      type: String,
      default: '#949693'
    }
  }
}
