
import theNotification from '@/components/notification'
import { validationMixin } from 'vuelidate'
import { required, email, integer } from 'vuelidate/lib/validators'
import { phone } from '@/core/validators'
import AButton from '@/components/elements/button'

export default {
  components: {
    theNotification,
    AButton
  },
  mixins: [
    validationMixin
  ],
  props: {
    options: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  data: () => ({
    drawingId: null,
    succes: false,
    showValidation: false,
    submitting: false,
    drawerName: '',
    drawerAge: '',
    givenName: '',
    familyName: '',
    email: '',
    tel: ''
  }),
  validations: {
    drawerName: { },
    drawerAge: { integer },
    givenName: { required },
    familyName: { required },
    email: { required, email },
    tel: { phone }
  },
  async mounted () {
    await this.$nextTick()
  },
  methods: {
    closeDrawingSubmission () {
      const notification = this.$refs.notification

      if (typeof notification?.close === 'function') {
        notification.close()
      }

      this.$router.push({ name: 'tegning-id', params: { id: this.drawingId } })
    },
    async onSubmit () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.showValidation = true
      } else {
        this.submitting = true

        const data = {
          drawerName: this.drawerName,
          drawerAge: this.drawerAge,
          givenName: this.givenName,
          familyName: this.familyName,
          email: this.email,
          tel: this.tel
        }

        try {
          const drawingBlob = this.options?.image
          const uploadDetails = await this.$api.getUploadDetails()
          const group = this.options?.group

          await this.$azureBlobStorage.uploadImage(uploadDetails.url, drawingBlob)
          data.drawing = uploadDetails.file
          const res = await this.$api.createDrawing(data, group)
          this.drawingId = res.id
          this.succes = true
          this.showValidation = false
          const drawingType = this.options.drawingType === 'upload' ? 'upload' : 'Tegning'

          const $gtm = window?.dataLayer || []
          $gtm.push({ event: 'tegning_upload', type: drawingType })
        } catch (error) {
          // eslint-disable-next-line
          console.log(error)
          this.submitting = false
        }
      }
    }
  }
}
