export const state = () => ({
  currentNotification: null,
  options: null
})

export const mutations = {
  SET_CURRENT_NOTIFICATION (state, options) {
    state.currentNotification = options.alias
    state.options = options
  },

  CLEAR_CURRENT_NOTIFICATION (state) {
    state.currentNotification = null
    state.options = null
  }
}
