import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7e6ee926 = () => interopDefault(import('../pages/opret-gruppe.vue' /* webpackChunkName: "pages/opret-gruppe" */))
const _93eeb156 = () => interopDefault(import('../pages/success.vue' /* webpackChunkName: "pages/success" */))
const _465275b6 = () => interopDefault(import('../pages/tegn.vue' /* webpackChunkName: "pages/tegn" */))
const _1493f6c0 = () => interopDefault(import('../pages/badge/_id.vue' /* webpackChunkName: "pages/badge/_id" */))
const _72361f54 = () => interopDefault(import('../pages/gruppe/_slug.vue' /* webpackChunkName: "pages/gruppe/_slug" */))
const _50dae1f6 = () => interopDefault(import('../pages/s/_secret.vue' /* webpackChunkName: "pages/s/_secret" */))
const _2066a947 = () => interopDefault(import('../pages/tegning/_id.vue' /* webpackChunkName: "pages/tegning/_id" */))
const _77e0be6c = () => interopDefault(import('../pages/upload/_id.vue' /* webpackChunkName: "pages/upload/_id" */))
const _21d9d724 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/opret-gruppe",
    component: _7e6ee926,
    name: "opret-gruppe"
  }, {
    path: "/success",
    component: _93eeb156,
    name: "success"
  }, {
    path: "/tegn",
    component: _465275b6,
    name: "tegn"
  }, {
    path: "/badge/:id?",
    component: _1493f6c0,
    name: "badge-id"
  }, {
    path: "/gruppe/:slug?",
    component: _72361f54,
    name: "gruppe-slug"
  }, {
    path: "/s/:secret?",
    component: _50dae1f6,
    name: "s-secret"
  }, {
    path: "/tegning/:id?",
    component: _2066a947,
    name: "tegning-id"
  }, {
    path: "/upload/:id?",
    component: _77e0be6c,
    name: "upload-id"
  }, {
    path: "/",
    component: _21d9d724,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
