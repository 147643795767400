
import theNotification from '@/components/notification'
import loadExternal from 'load-external'
import AButton from '@/components/elements/button'

export default {
  components: {
    theNotification,
    AButton
  },
  props: {
    options: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  computed: {
    formId () {
      return process.env.DONATION_FORM_ID || 11
    },
    formOf () {
      return parseInt(this.formId) === 13162 ? 'flygtning.onlinefundraising.dk' : 'sandbox.onlinefundraising.dk/flygtning'
    },
    drawing () {
      return this.options?.drawing
    },
    drawerName () {
      return this.drawing?.data?.drawerName
    },
    drawingId () {
      return this.drawing?.id
    },
    content () {
      const drawerText = this.drawerName ? `${this.drawerName}’s tegning og dermed` : ''
      return `Tak fordi DU vil støtte ${drawerText} tegning og dermed DRC Dansk Flygtningehjælps vigtige arbejde for familier på flugt. Nogle steder i verden bygger vi et midlertidigt hjem med en pressening for kun ${this?.$store?.state?.houseCost} kr. og andre steder kan vi bygge et helt hjem. Med DIN støtte kan vi sammen skabe hjem til verdens flygtninge.`
    },
    success () {
      return this.options?.success
    },
    groupId () {
      return this?.$store?.state?.route?.name === 'gruppe-slug' ? this.$store.state.groups.current.id : undefined
    }
  },
  async mounted () {
    await this.$nextTick()

    if (!this.success) {
      loadExternal('https://onlinefundraising.dk/js/3.8/iframe.js')

      const $gtm = window?.dataLayer || []
      $gtm.push({ event: 'drawing_donation_open' })
    }
  },
  methods: {
    closeDrawingSubmission () {
      const notification = this.$refs.notification

      if (typeof notification?.close === 'function') {
        notification.close()
      }
    }
  }
}
