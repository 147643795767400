
import scene from '@/components/scene'

export default {
  components: {
    scene
  },
  computed: {
    enableControls () {
      return this.$store.state.gallery.enableControls
    },
    shouldPlay () {
      return this.$store.state.gallery.play
    },
    drawings () {
      return this?.$store?.state?.gallery?.drawings || []
    }
  },
  watch: {
    $route () {
      this.$store.commit('gallery/SET_PLAY', false)
      this.close()
      this.stop()
    },
    shouldPlay () {
      this?.[this.shouldPlay ? 'start' : 'stop']?.()
    },
    enableControls () {
      if (this.enableControls) {
        (window?.dataLayer || []).push({ event: 'galleri_start' })
        this.open()
      } else {
        this.close()
      }
    }
  },
  beforeDestroy () {
    this.close()
  },
  methods: {
    onClick (object) {
      if (this.enableControls) {
        this.$store.commit('notifications/CLEAR_CURRENT_NOTIFICATION')
        this.close()
        return this.$router.push({ name: 'tegning-id', params: { id: object._id } })
      }
    },
    close () {
      if (window) {
        window.document.body.classList.remove('gallery')
        window.document.body.style.overflow = 'overlay'
      }
    },
    open () {
      this.$store.commit('gallery/ENABLE_CONTROLS', true)

      if (window) {
        window.document.body.style.overflow = 'hidden'
        window.document.body.classList.add('gallery')
      }
    },
    stop () {
      this.$refs?.scene?.stop?.()
    },
    start () {
      this.$refs?.scene?.run?.()
    },
    onOutro (e) {
      this.$emit('outro', e)
      this.$root.$emit('outro', e)
    }
  }
}
