export const state = () => ({
  current: null
})

export const actions = {
  async fetchCurrent ({ commit }, { slug }) {
    try {
      const groupEntry = await this.$api.getGroup(slug)

      if (groupEntry) {
        commit('SET_CURRENT', groupEntry)
        return groupEntry
      } else {
        throw new Error('Not Found')
      }
    } catch (error) {
      throw new Error(error)
    }
  }
}

export const mutations = {
  SET_CURRENT (state, groupEntry) {
    state.current = groupEntry
  }
}
