class Api {
  static $http = null

  static _setClient ($http) {
    this.$http = $http
  }

  /**
   * Get all approved drawings
   *
   * @returns Array
   */
  static all (groupId) {
    return this.$http.$get(`/drawings/${groupId ? 'group/' + groupId : ''}`)
  }

  /**
   * Get drawing by id
   *
   * @param {String} id
   */
  static get (id) {
    return this.$http.$get(`/drawings/${id}`)
  }

  /**
   * Create a new entity
   *
   * @param {Object} data
   * @param {Enum}   type
   * @returns {Object}
   */
  static create (data, type = 'drawing', group = null) {
    const payload = { data }
    return this.$http.post(`/entries/${type}${group ? '?group=' + group : ''}`, payload).then(res => res.json())
  }

  /**
   * Create a new Drawing entity
   *
   * @param {Object} data
   * @returns {Object}
   */
  static createDrawing (data, group = null) {
    return this.create(data, 'drawing', group)
  }

  /**
   * Create a new Order entity
   *
   * @param {Object} data
   */
  static createOrder (data) {
    return this.create(data, 'order')
  }

  /**
   * Create a new group entity
   *
   * @param {Object} data
   * @param {Enum}   type
   * @returns {Object}
   */
  static createGroup (data) {
    const payload = { data }
    return this.$http.post('/groups', payload).then(res => res.json())
  }

  static getGroup (slug) {
    return this.$http.$get(`/groups/${slug}`)
  }

  /**
   * Get details and signed URL for upload
   *
   * @param {String} ext
   * @returns {Object}
   */
  static getUploadDetails (ext = 'jpg') {
    return this.$http.$get(`/sign/${ext}`)
  }

  /**
   * Get donation amount
   *
   */
  static donations () {
    return this.$http.$get('/donations')
  }

  static getDataEntry (guid) {
    return this.$http.$get(`/dataset/${guid}`)
  }
}

export default function ({ $http, env }, inject) {
  // Create a custom HTTP instance
  const $api = $http.create({
    // See https://github.com/sindresorhus/ky#options
  })

  $api.setBaseURL(env.API_URL)
  $api.setHeader('Content-Type', 'application/json')
  $api.onError((error) => { throw error.response })

  Api._setClient($api)

  // Inject to context as $api
  inject('api', Api)
}
