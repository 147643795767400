export const state = () => ({
  donations: 0,
  route: null,
  houseCost: 218
})

export const actions = {
  async getDonations ({ commit }) {
    commit('SET_AMOUNT', (await this.$api.donations()).amount)
  }
}

export const mutations = {
  SET_AMOUNT (state, amount) {
    state.donations = amount
  },
  SET_ROUTE (state, route) {
    state.route = { name: route.name, params: route.params }
  }
}
