import { render, staticRenderFns } from "./footer.vue?vue&type=template&id=139a8a8e&scoped=true&"
import script from "./footer.vue?vue&type=script&lang=js&"
export * from "./footer.vue?vue&type=script&lang=js&"
import style0 from "./footer.vue?vue&type=style&index=0&id=139a8a8e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "139a8a8e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Footer: require('/codebuild/output/src998890840/src/dfh-tegn-et-hjem/components/footer.vue').default})
