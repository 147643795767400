
import buttonClose from '@/components/elements/buttons/close'
import { TimelineLite, TweenLite, Sine } from 'gsap'
import ModalTemplateResolver from './templates'

const DURATION = 0.150
const EASE_IN = Sine.easeIn
const EASE_OUT = Sine.easeOut

export default {
  components: {
    ModalTemplateResolver,
    buttonClose
  },
  props: {
    template: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  computed: {
    isGroupCtx () {
      return this.$route.name === 's-secret'
    }
  },
  mounted () {
    document.body.classList.add('overflow-hidden')
    document.addEventListener('keyup', this.onKeyup)
  },
  beforeDestroy () {
    document.body.classList.remove('overflow-hidden')
    document.removeEventListener('keyup', this.onKeyup)
  },
  methods: {
    close () {
      this.$store.dispatch('modal/close')
    },
    onKeyup (e) {
      if (e.code === 'Escape') {
        this.close()
      }
    },
    enter (el, done) {
      const backdrop = this.$refs.backdrop
      const content = this.$refs.content

      TweenLite.set(backdrop, { opacity: 0 })
      TweenLite.set(content, { opacity: 0 })

      const tl = new TimelineLite({
        onComplete: () => {
          done()
        }
      })

      tl.addLabel('start')

      if (backdrop) {
        tl.to(backdrop, DURATION, {
          autoAlpha: 1,
          ease: EASE_IN
        })
      }

      if (content) {
        tl.to(content, DURATION, {
          autoAlpha: 1,
          y: '-=10px',
          ease: EASE_IN
        }, 'start+=0.1')
      }
    },
    leave (el, done) {
      const backdrop = this.$refs.backdrop
      const content = this.$refs.content

      const tl = new TimelineLite({
        onComplete: () => {
          done()
        }
      })

      tl.addLabel('start')

      if (content) {
        tl.to(content, DURATION, {
          autoAlpha: 0,
          y: '+=10px',
          ease: EASE_OUT
        })
      }

      if (backdrop) {
        tl.to(backdrop, DURATION, {
          autoAlpha: 0,
          ease: EASE_OUT
        }, 'start+=0.1')
      }
    }
  }
}
