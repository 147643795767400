
// import iconCamera from '@/components/icons/camera'
// import iconDraw from '@/components/icons/draw'
// import accordionNavItem from '@/components/accordion/accordion-nav-item.vue'
// import accordionItem from '@/components/accordion/accordion-item'

export default {
  components: {
    // accordionItem,
    // accordionNavItem,
    // iconCamera
    // iconDraw
  },
  props: {
    isGroupCtx: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      activeIndex: this.isGroup ? 'Upload flere tegninger' : 'Upload en enkelt tegning'
    }
  },
  computed: {
    isGroup () {
      return !!this.$store.state.groups.current?.slug
    }
  },
  methods: {
    onToggle (title) {
      this.activeIndex = title
    }
  }
}
