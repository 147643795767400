import { helpers } from 'vuelidate/lib/validators'

export const phone = helpers.regex('phone', /^((\(?\+45\)?)?)(\s?\d{2}\s?\d{2}\s?\d{2}\s?\d{2})$/)

export const zip = helpers.regex('zip', /^\d{4}$/)

export default {
  phone,
  zip
}
