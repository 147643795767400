
import theFooter from '@/components/footer'
import theModal from '@/components/modal/index'
import NotificationResolver from '@/components/notifications/resolver'
import Fallback from '@/components/fallback'
import Gallery from '@/components/gallery'

import Bowser from 'bowser'

export default {
  components: {
    theFooter,
    theModal,
    NotificationResolver,
    Fallback,
    Gallery
  },
  data: () => ({
    ready: false,
    showFallback: false,
    fallbackInfo: {
      browser: null,
      trigger: null
    }
  }),
  computed: {
    modal () {
      return this.$store.state.modal
    },
    isInAppBrowser () {
      const ua = navigator.userAgent || navigator.vendor || window.opera
      // eslint-disable-next-line
      return (ua.indexOf('FBAN') > -1) || (ua.indexOf('FBAV') > -1)
    }
  },
  beforeMount () {
    this.resize()
    window.addEventListener('resize', this.resize, false)

    this.ready = true
  },
  methods: {
    resize () {
      const browser = Bowser.getParser(window.navigator.userAgent)
      this.fallbackInfo.browser = browser
      this.fallbackInfo.trigger = 'browser'

      const isInvalidBrowser = browser.satisfies({
        // or in general
        chrome: '<=76',
        firefox: '<=71',
        opera: '<=69',
        'internet explorer': '<=13',
        'microsoft edge': '<=79',
        safari: '<=12',
        mobile: {
          safari: '<=12',
          'android browser': '<3.9'
        }
      })

      this.showFallback = isInvalidBrowser
    }
  }
}
