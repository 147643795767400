import * as THREE from 'three'
// eslint-disable-next-line
import { clamp, distance, lerp } from '@heavyy/crate/functions'
import EventEmitter2 from 'eventemitter2'
const textureMap = new Map()

const between = (x, min, max) => {
  return x >= min && x <= max
}

// const images = [
//   'https://locker-51.s3-eu-west-1.amazonaws.com/TFES/school-children-drawing-62.jpg',
//   'https://locker-51.s3-eu-west-1.amazonaws.com/TFES/children-drawings-716334_1280.jpg',
//   'https://locker-51.s3-eu-west-1.amazonaws.com/TFES/10YT-D-Swaroop-Kumar-II-Abhyaas-School-Thorrur-Mahabubabad-District.jpeg',
//   'https://locker-51.s3-eu-west-1.amazonaws.com/TFES/house-75451_1280.jpg',
//   'https://locker-51.s3-eu-west-1.amazonaws.com/TFES/childs-drawing-home-rainbow-happy-600w-567590263.jpg',
//   'https://locker-51.s3-eu-west-1.amazonaws.com/TFES/20157364_1548018308594481_900442777945983976_o.jpg',
//   'https://locker-51.s3-eu-west-1.amazonaws.com/TFES/82099296_3171483686202255_4945028145485971456_o.jpg',
//   'https://locker-51.s3-eu-west-1.amazonaws.com/TFES/82176158_3176663165684307_302786415454846976_o.jpg',
//   'https://locker-51.s3-eu-west-1.amazonaws.com/TFES/95211472_3429343907082897_747987920605937664_o.jpg',
//   'https://locker-51.s3-eu-west-1.amazonaws.com/TFES/Cottage.jpg',
//   'https://locker-51.s3-eu-west-1.amazonaws.com/TFES/house-drawing-for-kids-65.jpg',
//   'https://locker-51.s3-eu-west-1.amazonaws.com/TFES/school-children-drawing-20.jpg',
//   'https://locker-51.s3-eu-west-1.amazonaws.com/TFES/children-picture-838372_1280.jpg',
//   'https://locker-51.s3-eu-west-1.amazonaws.com/TFES/children-picture-838372_1280.jpg',
//   'https://locker-51.s3-eu-west-1.amazonaws.com/TFES/fairy-tales-537198_1280.jpg',
//   'https://locker-51.s3-eu-west-1.amazonaws.com/TFES/family-879432_1280.jpg',
//   'https://locker-51.s3-eu-west-1.amazonaws.com/TFES/800px_COLOURBOX3209748.jpg',
//   'https://locker-51.s3-eu-west-1.amazonaws.com/TFES/83014400_3178007925549831_8353149037767032832_n.jpg',
//   'https://locker-51.s3-eu-west-1.amazonaws.com/TFES/87172080_3267276943289595_4175919456913981440_n.jpg',
//   'https://locker-51.s3-eu-west-1.amazonaws.com/TFES/90559197_3343709082313047_2424155262135828480_n.jpg',
//   'https://locker-51.s3-eu-west-1.amazonaws.com/TFES/89015804_3296261513724471_4676888187530903552_n.jpg',
//   'https://locker-51.s3-eu-west-1.amazonaws.com/TFES/93793992_3406402469377041_1836765677784924160_n.jpg',
//   'https://locker-51.s3-eu-west-1.amazonaws.com/TFES/93910179_3408896002461021_6992499395484712960_o.jpg',
//   'https://locker-51.s3-eu-west-1.amazonaws.com/TFES/Country-House-650.jpg'
// ]

export default class Scene extends EventEmitter2 {
  constructor (images = []) {
    super()

    this.setTreshold()

    this.images = images
    this.scene = new THREE.Scene()
    this.raycaster = new THREE.Raycaster()
    this.mouse = new THREE.Vector2()
    this.clock = new THREE.Clock()

    this.positions = []

    this.theta = 0.0
    this.allowScroll = false
    this.enableControls = false
    this.wheelUsed = false
    this.wheelTimeout = null

    this.rotationY = null
    this.rotationX = null

    this.lastZ = null

    this.touchStarted = false
    this.touchPosition = {
      x: 0,
      y: 0
    }

    this.isDragging = false
    this.dragPositions = {
      start: { x: 0, y: 0 },
      end: { x: 0, y: 0 }
    }

    this.distance = {
      z: 0,
      x: 0,
      y: 0
    }

    this.fov = 70
    this.aspect = window.innerWidth / window.innerHeight
    this.near = 1
    this.far = 1000

    this.camera = new THREE.PerspectiveCamera(
      this.fov,
      this.aspect,
      this.near,
      this.far
    )

    this.camera.lookAt(this.scene.position)
    this.camera.updateMatrixWorld()

    this.light = new THREE.DirectionalLight(0xFFFFFF, 1)
    this.light.position.set(-1, 2, 4)
    this.scene.add(this.light)

    this.fog = new THREE.Fog(0xFFFFFF, this.near, this.far)
    this.scene.fog = this.fog

    this.perTile = 30
    this.tiles = [...new Array(Math.ceil(1000 / this.perTile)).keys()]
    this.gridSpace = -150
    this.reuseGap = 5
    this.positions = this.tiles.map(() => [])
    this.sheets = []

    if (this.images.length) {
      this.addPlanes()
    }

    this.renderer = new THREE.WebGLRenderer({
      alpha: true
    })

    this.renderer.setPixelRatio(clamp(1.5, 1, window.devicePixelRatio))
    this.renderer.setSize(window.innerWidth, window.innerHeight)
    this.renderer.setClearColor(0xF2F2F2, 0)

    this.onWheelBind = this.onWheel.bind(this)
    this.onTouchStartBind = this.onTouchStart.bind(this)
    this.onTouchMoveBind = this.onTouchMove.bind(this)
    this.onTouchEndBind = this.onTouchEnd.bind(this)
    this.onClickBind = this.onClick.bind(this)
    this.onMouseMoveBind = this.onMouseMove.bind(this)
    this.resizeBind = this.resize.bind(this)

    window.addEventListener('wheel', this.onWheelBind)
    window.addEventListener('touchstart', this.onTouchStartBind)
    window.addEventListener('touchmove', this.onTouchMoveBind)
    window.addEventListener('touchend', this.onTouchEndBind)
    window.addEventListener('click', this.onClickBind)
    window.addEventListener('mousemove', this.onMouseMoveBind)
    window.addEventListener('resize', this.resizeBind)
  }

  async addPlanes () {
    const portrait = new THREE.PlaneBufferGeometry(22, 32, 2)
    const landscape = new THREE.PlaneBufferGeometry(32, 22, 2)

    const sheets = []

    await Promise.all(this.tiles.map(async (index) => {
      for (let i = 0; i <= this.perTile; i++) {
        const { texture, node, ratio } = await this.getTexture()

        const material = new THREE.MeshPhongMaterial({ color: 0xFEFEFE, map: texture })
        const object = new THREE.Mesh(ratio < 1 ? portrait : landscape, material)

        object._id = node.id

        // material.onBeforeCompile = shader => {
        //   console.log(shader.fragmentShader)
        //   shader.fragmentShader = shader.fragmentShader.replace(
        //     "gl_FragColor = vec4( outgoingLight, diffuseColor.a );",
        //     [
        //       "gl_FragColor = vec4( outgoingLight, diffuseColor.a );",
        //       "gl_FragColor.a *= pow(gl_FragCoord.z, 100.0);"
        //     ].join("\n")
        //   );
        // };

        this.generateLocation(index, i, object)

        const elapsed = this.clock.getElapsedTime()
        object.rotation.x = Math.sin(elapsed) * 0.09
        object.rotation.y = Math.cos(elapsed) * 0.0

        // object.rotation.x = Math.random() * 2 * Math.PI
        // object.rotation.y = Math.random() * 2 * Math.PI
        object.rotation.z = Math.cos(index + i) * 0.493

        if (!sheets[index]) {
          sheets[index] = {
            objects: [],
            z: null
          }
        }

        sheets[index].objects.push({
          object,
          material,
          texture,
          z: object.position.z
        })

        this.scene.add(object)
      }
    }))

    sheets.map((sheet) => {
      sheet.z = sheet.objects.reduce((a, b) => {
        return a.z < b.z ? a.z : b.z
      })

      return sheet
    })

    this.sheets = sheets
  }

  destroy () {
    window.removeEventListener('wheel', this.onWheelBind)
    window.removeEventListener('touchstart', this.onTouchStartBind)
    window.removeEventListener('touchmove', this.onTouchMoveBind)
    window.removeEventListener('touchend', this.onTouchEndBind)
    window.removeEventListener('click', this.onClickBind)
    window.removeEventListener('mousemove', this.onMouseMoveBind)
    window.removeEventListener('resize', this.resizeBind)
    document.body.classList.remove('cursor-pointer')
  }

  generateLocation (index, i, object) {
    const positions = this.positions[index]

    const x = Math.random() * this.fov * Math.cos(i) * 10
    const y = Math.random() * 40 * Math.sin(i) * 10
    const z = (index * this.gridSpace) + (Math.cos(i) * 5)

    const offsetX = 35
    const offsetY = 55
    const hit = positions.find((pos) => {
      return between(pos.x, x - offsetX, x + offsetX) && between(pos.y, y - offsetY, y + offsetY)
    })

    if (hit) {
      return this.generateLocation(index, i, object)
    }

    object.position.x = x
    object.position.y = y
    object.position.z = z

    this.positions[index].push(object.position)
  }

  getTexture () {
    // eslint-disable-next-line
    return new Promise(async (resolve, reject) => {
      const item = this.images.shift()
      this.images.push(item)

      if (textureMap.has(item.image)) {
        const texture = textureMap.get(item.image)
        const { width, height } = texture.image
        return resolve({ texture, node: item, width, height, ratio: width / height })
      }

      const { texture, width, height } = await new Promise((resolve) => {
        const texture = new THREE.TextureLoader().load(item.image, (tex) => {
          const { width, height } = tex.image
          resolve({ texture, width, height })
        })
      })

      texture.minFilter = {}

      textureMap.set(item.image, texture)

      return resolve({ texture, node: item, width, height, ratio: width / height })
    })
  }

  render () {
    // const elapsed = this.clock.getElapsedTime()
    // this.camera.rotation.z -= 0.0005;

    this.camera.updateMatrix()
    this.camera.updateMatrixWorld()

    // const frustum = new THREE.Frustum()

    // frustum.setFromProjectionMatrix(new THREE.Matrix4().multiplyMatrices(this.camera.projectionMatrix, this.camera.matrixWorldInverse))

    // for (let i = 0; i < this.scene.children.length; i++) {
    //   const child = this.scene.children[i]
    //   if (child.constructor.name === 'Mesh') {
    //     if (frustum.containsPoint(child.position)) {
    //       child.visible = true
    //       child.rotation.x = Math.sin(elapsed) * 0.09
    //       child.rotation.y = Math.cos(elapsed) * 0.0
    //     }
    //   }
    // }

    this.distance.z += (this.wheelUsed ? 0 : this.enableControls ? -0.5 : 0)

    const pos = this.far + this.distance.z

    if (pos !== this.camera.position.z) {
      const offBounds = (this.sheets || []).find(sheet => sheet.z + (this.reuseGap * this.gridSpace) >= pos)

      if (offBounds) {
        // const index = this.sheets.indexOf(offBounds)
        const lastZ = Math.min(...this.sheets.map(sheet => sheet.z))
        const nextBase = (Math.round(lastZ / this.gridSpace) * this.gridSpace) + this.gridSpace

        offBounds.z = nextBase

        offBounds.objects.forEach((item, i) => {
          item.object.position.z = nextBase + (Math.cos(i) * 5)
        })
      }
    }

    if (this.allowScroll && !isNaN(this.rotationY) && !isNaN(this.rotationX)) {
      this.camera.rotation.y = lerp(this.camera.rotation.y, this.rotationY, 0.025)
      this.camera.rotation.x = lerp(this.camera.rotation.x, this.rotationX, 0.025)
    }

    this.camera.position.z = pos

    // this.resize()

    this.renderer.render(this.scene, this.camera)
  }

  intersection () {
    this.raycaster.setFromCamera(this.mouse, this.camera)
    const intersects = this.raycaster.intersectObjects(this.scene.children)

    if (intersects.length === 0) {
      return false
    }

    const item = intersects.reduce((a, b) => a.distance < b.distance ? a : b)

    if (item.distance > (this.far * 0.75)) {
      return null
    }

    return item
  }

  onClick () {
    const item = this.intersection()

    if (item) {
      this.emit('click', item.object)
    }
  }

  run () {
    const elapsed = this.clock.getElapsedTime()

    for (let i = 0; i < this.scene.children.length; i++) {
      const plane = this.scene.children[i]

      if (plane.updatePosition) {
        plane.updatePosition(window.scrollY)
        plane.updateTime(elapsed)
      }
    }

    this.render()
  }

  setDistance (z) {
    if (z > this.distance.z && !this.lastZ) {
      this.lastZ = z // scrolling back
    } else if (z < this.distance.z) {
      this.lastZ = null
    }

    if ((z - this.lastZ) >= this.threshold) {
      this.emit('outro')
    }

    this.distance.z = z
  }

  onWheel (e) {
    if (this.allowScroll) {
      const distance = (e.deltaY * 0.15)

      if (Math.abs(distance) <= 10) {
        this.wheelUsed = false
      } else {
        this.wheelUsed = true
        clearTimeout(this.wheelTimeout)
      }

      this.setDistance(this.distance.z + distance)
    }
  }

  onTouchStart (e) {
    if (!this.allowScroll) {
      return
    }

    this.touchStarted = true

    this.touchPosition = {
      x: e.touches[0].clientX,
      y: e.touches[0].clientY
    }
  }

  onTouchMove (e) {
    if (!this.allowScroll || !this.touchStarted) {
      return
    }

    const dis = distance(this.touchPosition.x, e.touches[0].clientX, this.touchPosition.y, e.touches[0].clientY)
    const factor = this.touchPosition.y > e.touches[0].clientY ? -1 : 1

    this.setDistance(this.distance.z + (dis * 0.2) * factor)
  }

  onTouchEnd (e) {
    if (!this.allowScroll) {
      return
    }

    const dis = distance(this.touchPosition.x, e.changedTouches[0].clientX, this.touchPosition.y, e.changedTouches[0].clientY)

    if (dis < 5) {
      this.onMouseMove({
        clientX: e.changedTouches[0].clientX,
        clientY: e.changedTouches[0].clientY
      })

      this.onClick()
    }

    // eslint-disable-next-line
    console.log(dis)

    this.touchStarted = false
  }

  onMouseDown (e) {
    e.preventDefault()
    this.isDragging = true
    this.dragPositions.start.x = e.clientX
    this.dragPositions.end.x = e.clientX
    this.dragPositions.start.y = e.clientY
    this.dragPositions.end.y = e.clientY
  }

  onMouseMove (e) {
    this.mouse.x = (e.clientX / window.innerWidth) * 2 - 1
    this.mouse.y = -(e.clientY / window.innerHeight) * 2 + 1

    const center = {
      x: window.innerWidth / 2,
      y: window.innerHeight / 2
    }

    this.rotationY = (e.clientX - center.x) * 0.0002 * -1
    this.rotationX = (e.clientY - center.y) * 0.0004 * -1

    if (this.enableControls && document.body.classList.contains('gallery')) {
      const item = this.intersection()

      if (item) {
        document.body.classList.add('cursor-pointer')
      } else {
        document.body.classList.remove('cursor-pointer')
      }
    }
  }

  onMouseUp () {
    this.isDragging = false
  }

  getRenderer () {
    const canvas = this.renderer.domElement
    canvas.classList.add('dom-gl')

    return canvas
  }

  setTreshold () {
    this.threshold = window.innerWidth > 968 ? 100 : 225
  }

  resize () {
    this.renderer.setSize(window.innerWidth, window.innerHeight)
    this.camera.aspect = window.innerWidth / window.innerHeight
    this.camera.updateProjectionMatrix()
    this.setTreshold()

    for (let i = 0; i < this.scene.children.length; i++) {
      const plane = this.scene.children[i]
      if (plane.resize) {
        plane.resize()
      }
    }
  }
}
