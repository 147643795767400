export const state = () => ({
  open: false,
  template: null
})

export const mutations = {
  OPEN (state, template) {
    state.open = true
    state.template = template
  },
  CLOSE (state) {
    state.open = false
    state.template = null
  }
}

export const actions = {
  open ({ commit }, template) {
    commit('OPEN', template)
  },
  close ({ commit }) {
    commit('CLOSE')
  }
}
