
import { LocalStorage } from 'ttl-localstorage'
import theNotification from '@/components/notification'
import iconDrag from '@/components/icons/drag'
import iconPan from '@/components/icons/pan'
import iconClick from '@/components/icons/click'

export default {
  components: {
    theNotification,
    iconDrag,
    iconPan,
    iconClick
  },
  methods: {
    onClose (e) {
      e.stopImmediatePropagation()
      // key will expire in 1 day
      LocalStorage.put('HELPER_GALLERY', null, 86400)
    }
  }
}
