
import theNotification from '@/components/notification'
import { validationMixin } from 'vuelidate'
import { required, email, integer, minValue } from 'vuelidate/lib/validators'
import { phone } from '@/core/validators'
import AButton from '@/components/elements/button'

const fullName = val => `${val}`.trim().split(' ').length > 1

export default {
  components: {
    theNotification,
    AButton
  },
  mixins: [
    validationMixin
  ],
  props: {
    options: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  data: () => ({
    slug: null,
    succes: false,
    showValidation: false,
    submitting: false,
    groupName: '',
    amount: '',
    contactPerson: '',
    tel: '',
    email: '',
    address: '',
    city: '',
    zip: ''
  }),
  validations: {
    groupName: { required },
    amount: { required, minValue: minValue(0) },
    contactPerson: { required, fullName },
    tel: { required, phone },
    email: { required, email },
    address: { required },
    city: { required },
    zip: { required, integer, minValue: minValue(4) }
  },
  async mounted () {
    await this.$nextTick()
  },
  methods: {
    closeGroupSubmission () {
      const notification = this.$refs.notification

      if (typeof notification?.close === 'function') {
        notification.close()
      }

      if (this.slug) {
        this.$router.push({ name: 'gruppe-slug', params: { slug: this.slug } })
      }
    },
    async onSubmit () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.showValidation = true
      } else {
        this.submitting = true

        const data = {
          name: this.groupName,
          amount: this.amount,
          contactPerson: this.contactPerson,
          tel: this.tel,
          email: this.email,
          address: this.address,
          city: this.city,
          zip: this.zip
        }

        const res = await this.$api.createGroup(data)

        this.slug = res.slug
        this.succes = true
        this.showValidation = false

        const $gtm = window?.dataLayer || []
        $gtm.push({ event: 'gruppe_oprettet' })

        this.submitting = false
      }
    }
  }
}
