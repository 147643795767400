
import theNotification from '@/components/notification'
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import { phone, zip } from '@/core/validators'
import AButton from '@/components/elements/button'

export default {
  components: {
    theNotification,
    AButton
  },
  mixins: [
    validationMixin
  ],
  data: () => ({
    succes: false,
    showValidation: false,
    submitting: false,
    givenName: '',
    familyName: '',
    road: '',
    zip: '',
    city: '',
    email: '',
    tel: ''
  }),
  validations: {
    givenName: { required },
    familyName: { required },
    road: { required },
    zip: { required, zip },
    city: { required },
    email: { required, email },
    tel: { required, phone }
  },
  mounted () {
    (window?.dataLayer || []).push({ event: 'kit_open' })
  },
  methods: {
    closeOrderDrawkit () {
      const notification = this.$refs.notification

      if (typeof notification?.close === 'function') {
        notification.close()
      }

      this.$router.push('/tegn')
    },
    async onSubmit () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.showValidation = true
      } else {
        this.submitting = true

        const data = {
          givenName: this.givenName,
          familyName: this.familyName,
          road: this.road,
          zip: this.zip,
          city: this.city,
          email: this.email,
          tel: this.tel
        }

        try {
          await this.$api.createOrder(data)
          this.succes = true
          this.showValidation = false
          const $gtm = window?.dataLayer || []
          $gtm.push({ event: 'tegnekit_bestilling' })
        } catch (error) {
          // eslint-disable-next-line
          console.log(error)
          this.submitting = false
        }
      }
    }
  }
}
