
export default {
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    type: {
      type: String,
      required: false,
      default: 'submit'
    }
  }
}
