const NOTIFICATIONS = {
  DRAWING_DONATION: require('./drawing-donation').default,
  DRAWING_SUBMISSION: require('./drawing-submission').default,
  GROUP_SUBMISSION: require('./create-group').default,
  GENERAL_DONATION: require('./general-donation').default,
  ORDER_DRAWKIT: require('./order-drawkit').default,
  HELPER_DRAWER: require('./helper-drawer').default,
  HELPER_GALLERY: require('./helper-gallery').default,
  HELPER_UPLOAD: require('./helper-upload').default,
  SHARE_DRAWING: require('./helper-share').default
}

export default {
  computed: {
    notification () {
      return this.$store.state.notifications.currentNotification
    },

    notificationOptions () {
      return this.$store.state.notifications.options
    },
    keys  () {
      return Object.keys(NOTIFICATIONS)
    }
  },
  render (h) {
    if (!this.notification || !this.keys.includes(this.notification)) {
      return
    }

    return h(NOTIFICATIONS[this.notification], { props: { options: this.notificationOptions } })
  }
}
