export const modalTemplates = {
  modalVideo: require('./video').default,
  modalEntry: require('./entry').default
}

export const contentTypeToView = (type, views = {}) => {
  if (Object.keys(views).includes(type) < 0) {
    return null
  }

  return views[type]
}

export default {
  abstract: true,
  props: {
    item: {
      type: Object,
      required: true
    },
    isGroupCtx: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  render (h) {
    const c = contentTypeToView(this.item.type, modalTemplates)

    if (c) {
      return h(c, { props: { item: this.item, isGroupCtx: this.isGroupCtx } })
    }
  }
}
