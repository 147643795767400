
import theNotification from '@/components/notification'

export default {
  components: {
    theNotification
  },
  data: () => ({
    succes: false,
    label: 'Kopier'
  }),
  computed: {
    url () {
      const url = new URL(window.location.href)
      const shareUrl = url.origin + url.pathname
      return shareUrl
    },
    prettyUrl () {
      const url = this.url ? this.url.replace(/(^\w+:|^)\/\//, '') : null

      if (!url) {
        return ''
      }

      return /^http\s?:\/\//.test(url) ? url : `https://${url}`
    }
  },
  async mounted () {
    await this.$nextTick()
    setTimeout(() => {
      this.succes = false
    }, 2000)
  },
  methods: {
    onCopy (e) {
      this.label = 'Kopieret'
    }
  }
}
