export const state = () => ({
  current: null
})

export const actions = {
  async fetchCurrent ({ commit }, { id }) {
    try {
      const drawingEntry = await this.$api.get(id)

      if (drawingEntry) {
        commit('SET_CURRENT', drawingEntry)
      } else {
        throw new Error('Not Found')
      }
    } catch (error) {
      throw new Error(error)
    }
  }
}

export const mutations = {
  SET_CURRENT (state, drawingEntry) {
    state.current = drawingEntry
  }
}
