import Vue from 'vue'
import { bootstrap } from '@heavyy/wah-bootstrap/helpers'
import VueClipboard from 'vue-clipboard2'

bootstrap()

Vue.use(VueClipboard)

if (!window.CSS || !window.CSS.supports('color', 'var(--fake-var)')) {
  window.addEventListener('DOMContentLoaded', () => {
    import(/* webpackChunkName: "css-vars-ponyfill" */ 'css-vars-ponyfill').then((module) => {
      const cssVars = module.default

      cssVars({
        include: 'link[rel=stylesheet],style',
        watch: true
      })
    })
  })
}
