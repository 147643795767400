export const state = () => ({
  enableControls: false,
  play: false,
  drawings: []
})

export const actions = {
  async loadDrawings ({ commit }, groupId = null) {
    const drawings = await this.$api.all(groupId).then((items) => {
      const CDN_URL = process.env.CDN_URL.toLowerCase().includes('tegnethjemstorprd.azureedge.net') ? 'https://drc-teh.imgix.net' : process.env.CDN_URL

      return items.map(item => ({
        id: item.id,
        image: item?.data?.drawing ? (CDN_URL + '/' + item.data.drawing + '?w=400&fit=clip&fm=jpg&q=60') : null
      })).filter(item => item.image)
    }).catch((err) => {
      // eslint-disable-next-line
      console.log(err)

      return []
    })

    commit('SET_DRAWINGS', drawings)
  }
}

export const mutations = {
  ENABLE_CONTROLS (state, flag) {
    state.enableControls = flag
  },
  SET_PLAY (state, flag) {
    state.play = flag
  },
  SET_DRAWINGS (state, drawings) {
    state.drawings = drawings
  }
}
