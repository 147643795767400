
import { TweenLite } from 'gsap'
import { Ticker } from '@heavyy/crate/utils'
import Scene from '@/core/gl/Scene'
// import Plane from "@/core/gl/Plane"

export default {
  props: {
    enableControls: {
      type: Boolean,
      required: false,
      default: false
    },
    drawings: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data: () => ({
    runAniamate: false
  }),
  watch: {
    enableControls: {
      immediate: true,
      handler () {
        if (this.$options.scene) {
          this.$options.scene.allowScroll = this.enableControls
        }
      }
    }
  },
  async mounted () {
    await this.$nextTick()

    // eslint-disable-next-line
    console.log(this.$el)

    this.$options.scene = new Scene([...this.drawings])

    this.$el.appendChild(this.$options.scene.getRenderer())

    // const planes = document.querySelectorAll(".js-plane")
    // Array.from(planes).forEach(el => {
    //   new Plane(el, this.$options.scene)
    //   el.style.visibility = "hidden"
    // })

    this.$options.scene.on('outro', e => this.$emit('outro', e))
    this.$options.scene.on('click', e => this.$emit('click', e))

    TweenLite.to(this.$options.scene.distance, 3.0, {
      z: -(this.$options.scene.far * 0.55),
      onComplete: () => {
        this.$options.scene.enableControls = true
      }
    })

    this.$options.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          this.stop()
        } else {
          this.run()
        }
      })
    }, { threshold: [0.1, 0] })

    this.$options.observer.observe(this.$options.scene.getRenderer())
  },
  beforeDestroy () {
    this.$options.scene.destroy()
    Ticker.remove(this.animate)
    this.$options.scene = null
  },
  methods: {
    stop () {
      this.runAniamate = false
      Ticker.remove(this.animate)
    },

    run () {
      this.runAniamate = true
      Ticker.add(this.animate)
    },

    animate () {
      if (!this.runAniamate) {
        return
      }

      this?.$options?.scene?.run()
    }
  }
}
