
import { gsap, Power3 } from 'gsap'
import AButton from '@/components/elements/button'

export default {
  components: {
    AButton
  },
  props: {
    title: {
      type: String,
      required: false,
      default: null
    },
    showDescription: {
      type: Boolean,
      required: false,
      default: false
    },
    actionLabel: {
      type: String,
      required: false,
      default: null
    },
    disclaimer: {
      type: Boolean,
      required: false,
      default: false
    },
    showLayer: {
      type: Boolean,
      required: false,
      default: false
    },
    isHelper: {
      type: Boolean,
      required: false,
      default: false
    },
    actionIsBusy: {
      type: Boolean,
      required: false,
      default: false
    },
    hideBackdrop: {
      type: Boolean,
      required: false,
      default: false
    },
    disableBackdropClick: {
      type: Boolean,
      required: false,
      default: false
    },
    offsetBottom: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  async mounted () {
    await this.$nextTick()

    const notification = this.$refs.notification
    const overlay = this.$refs.overlay

    if (notification) {
      gsap.set(notification, { x: '+=100%', opacity: 1 })
    }

    document.body.classList.add('overflow-hidden')

    const tl = gsap.timeline()

    if (overlay) {
      tl.to(overlay, 0.125, { opacity: 1 }, 'start')
    }

    if (notification) {
      tl.to(notification, 0.125, { x: '-=100%' }, 'start')
    }
  },
  beforeDestroy () {
    document.body.classList.remove('overflow-hidden')
  },
  methods: {
    close (e) {
      if (e) {
        e.stopImmediatePropagation()
      }

      const notification = this.$refs.notification
      const overlay = this.$refs.overlay

      const tl = gsap.timeline({
        onComplete: () => {
          this.$store.commit('notifications/CLEAR_CURRENT_NOTIFICATION')
          this.$emit('close', e)
        }
      })

      if (overlay) {
        tl.to(overlay, 0.125, { ease: Power3.easeInOut, opacity: 0 }, 'start')
      }

      if (notification) {
        tl.to(notification, 0.125, { ease: Power3.easeInOut, x: '+=100%' }, 'start')
      }
    },
    clickBackdrop (e) {
      if (this.disableBackdropClick) {
        return
      }

      this.close(e)
    }
  }
}
