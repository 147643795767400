
export default {
  props: {
    info: {
      type: Object,
      required: true
    }
  },
  computed: {
    browserName () {
      const alias = this.info.browser.getBrowserName().toLowerCase()

      switch (alias) {
        case 'chrome':
          return 'Google Chrome'
        case 'safari':
          return 'Safari'
        default:
          return this.info.browser.getBrowserName()
      }
    }
  }
}
