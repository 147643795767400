class AzureBlobStorage {
  static $http = null

  static _setClient ($http) {
    this.$http = $http
  }

  /**
   * Get all approved drawings
   *
   * @returns Array
   */
  static uploadImage (signedUrl, image) {
    return this.$http.put(signedUrl, image)
  }
}

export default function ({ $http, env }, inject) {
  // Create a custom HTTP instance
  const $azureBlobStorage = $http.create({
    // See https://github.com/sindresorhus/ky#options
  })

  $azureBlobStorage.setHeader('Content-Type', 'image/jpeg')
  $azureBlobStorage.setHeader('x-ms-blob-type', 'BlockBlob')
  $azureBlobStorage.onError((error) => { throw error.response })

  AzureBlobStorage._setClient($azureBlobStorage)

  // Inject to context as $azureBlobStorage
  inject('azureBlobStorage', AzureBlobStorage)
}
