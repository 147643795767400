
import { LocalStorage } from 'ttl-localstorage'
import theNotification from '@/components/notification'
import iconDraw from '@/components/icons/draw'
import iconErase from '@/components/icons/erase'
import iconThickness from '@/components/icons/thickness'
import iconUndo from '@/components/icons/undo'
import iconClear from '@/components/icons/clear'

export default {
  components: {
    theNotification,
    iconDraw,
    iconErase,
    iconThickness,
    iconUndo,
    iconClear
  },
  data: () => ({
    succes: false
  }),
  methods: {
    onClose () {
      LocalStorage.put('HELPER_DRAWER', null)
    }
  }
}
