export const Fallback = () => import('../../components/fallback.vue' /* webpackChunkName: "components/fallback" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const Gallery = () => import('../../components/gallery.vue' /* webpackChunkName: "components/gallery" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../../components/header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const Notification = () => import('../../components/notification.vue' /* webpackChunkName: "components/notification" */).then(c => wrapFunctional(c.default || c))
export const Scene = () => import('../../components/scene.vue' /* webpackChunkName: "components/scene" */).then(c => wrapFunctional(c.default || c))
export const AccordionItem = () => import('../../components/accordion/accordion-item.vue' /* webpackChunkName: "components/accordion-item" */).then(c => wrapFunctional(c.default || c))
export const AccordionNavItem = () => import('../../components/accordion/accordion-nav-item.vue' /* webpackChunkName: "components/accordion-nav-item" */).then(c => wrapFunctional(c.default || c))
export const BlocksColumns = () => import('../../components/blocks/columns.vue' /* webpackChunkName: "components/blocks-columns" */).then(c => wrapFunctional(c.default || c))
export const BlocksCounter = () => import('../../components/blocks/counter.vue' /* webpackChunkName: "components/blocks-counter" */).then(c => wrapFunctional(c.default || c))
export const BlocksHeroSimple = () => import('../../components/blocks/hero-simple.vue' /* webpackChunkName: "components/blocks-hero-simple" */).then(c => wrapFunctional(c.default || c))
export const BlocksHero = () => import('../../components/blocks/hero.vue' /* webpackChunkName: "components/blocks-hero" */).then(c => wrapFunctional(c.default || c))
export const BlocksSteps = () => import('../../components/blocks/steps.vue' /* webpackChunkName: "components/blocks-steps" */).then(c => wrapFunctional(c.default || c))
export const BlocksText = () => import('../../components/blocks/text.vue' /* webpackChunkName: "components/blocks-text" */).then(c => wrapFunctional(c.default || c))
export const BlocksVideo = () => import('../../components/blocks/video.vue' /* webpackChunkName: "components/blocks-video" */).then(c => wrapFunctional(c.default || c))
export const ElementsGroupPic = () => import('../../components/elements/GroupPic.vue' /* webpackChunkName: "components/elements-group-pic" */).then(c => wrapFunctional(c.default || c))
export const ElementsButton = () => import('../../components/elements/button.vue' /* webpackChunkName: "components/elements-button" */).then(c => wrapFunctional(c.default || c))
export const ElementsCampaignlogo = () => import('../../components/elements/campaignlogo.vue' /* webpackChunkName: "components/elements-campaignlogo" */).then(c => wrapFunctional(c.default || c))
export const ElementsCursor = () => import('../../components/elements/cursor.vue' /* webpackChunkName: "components/elements-cursor" */).then(c => wrapFunctional(c.default || c))
export const ElementsLogoHouse = () => import('../../components/elements/logo-house.vue' /* webpackChunkName: "components/elements-logo-house" */).then(c => wrapFunctional(c.default || c))
export const ElementsLogo = () => import('../../components/elements/logo.vue' /* webpackChunkName: "components/elements-logo" */).then(c => wrapFunctional(c.default || c))
export const IconsArrow = () => import('../../components/icons/arrow.vue' /* webpackChunkName: "components/icons-arrow" */).then(c => wrapFunctional(c.default || c))
export const IconsCamera = () => import('../../components/icons/camera.vue' /* webpackChunkName: "components/icons-camera" */).then(c => wrapFunctional(c.default || c))
export const IconsCheckmark = () => import('../../components/icons/checkmark.vue' /* webpackChunkName: "components/icons-checkmark" */).then(c => wrapFunctional(c.default || c))
export const IconsClear = () => import('../../components/icons/clear.vue' /* webpackChunkName: "components/icons-clear" */).then(c => wrapFunctional(c.default || c))
export const IconsClick = () => import('../../components/icons/click.vue' /* webpackChunkName: "components/icons-click" */).then(c => wrapFunctional(c.default || c))
export const IconsClose = () => import('../../components/icons/close.vue' /* webpackChunkName: "components/icons-close" */).then(c => wrapFunctional(c.default || c))
export const IconsColor = () => import('../../components/icons/color.vue' /* webpackChunkName: "components/icons-color" */).then(c => wrapFunctional(c.default || c))
export const IconsCrop = () => import('../../components/icons/crop.vue' /* webpackChunkName: "components/icons-crop" */).then(c => wrapFunctional(c.default || c))
export const IconsDrag = () => import('../../components/icons/drag.vue' /* webpackChunkName: "components/icons-drag" */).then(c => wrapFunctional(c.default || c))
export const IconsDraw = () => import('../../components/icons/draw.vue' /* webpackChunkName: "components/icons-draw" */).then(c => wrapFunctional(c.default || c))
export const IconsErase = () => import('../../components/icons/erase.vue' /* webpackChunkName: "components/icons-erase" */).then(c => wrapFunctional(c.default || c))
export const IconsExpand = () => import('../../components/icons/expand.vue' /* webpackChunkName: "components/icons-expand" */).then(c => wrapFunctional(c.default || c))
export const IconsHelp = () => import('../../components/icons/help.vue' /* webpackChunkName: "components/icons-help" */).then(c => wrapFunctional(c.default || c))
export const IconsHome = () => import('../../components/icons/home.vue' /* webpackChunkName: "components/icons-home" */).then(c => wrapFunctional(c.default || c))
export const IconsInformation = () => import('../../components/icons/information.vue' /* webpackChunkName: "components/icons-information" */).then(c => wrapFunctional(c.default || c))
export const IconsPaint = () => import('../../components/icons/paint.vue' /* webpackChunkName: "components/icons-paint" */).then(c => wrapFunctional(c.default || c))
export const IconsPan = () => import('../../components/icons/pan.vue' /* webpackChunkName: "components/icons-pan" */).then(c => wrapFunctional(c.default || c))
export const IconsRedo = () => import('../../components/icons/redo.vue' /* webpackChunkName: "components/icons-redo" */).then(c => wrapFunctional(c.default || c))
export const IconsRotate = () => import('../../components/icons/rotate.vue' /* webpackChunkName: "components/icons-rotate" */).then(c => wrapFunctional(c.default || c))
export const IconsSwipe = () => import('../../components/icons/swipe.vue' /* webpackChunkName: "components/icons-swipe" */).then(c => wrapFunctional(c.default || c))
export const IconsThickness = () => import('../../components/icons/thickness.vue' /* webpackChunkName: "components/icons-thickness" */).then(c => wrapFunctional(c.default || c))
export const IconsUndo = () => import('../../components/icons/undo.vue' /* webpackChunkName: "components/icons-undo" */).then(c => wrapFunctional(c.default || c))
export const Modal = () => import('../../components/modal/index.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c))
export const ModulesDrawingBoard = () => import('../../components/modules/drawing-board.vue' /* webpackChunkName: "components/modules-drawing-board" */).then(c => wrapFunctional(c.default || c))
export const ModulesImageCropper = () => import('../../components/modules/image-cropper.vue' /* webpackChunkName: "components/modules-image-cropper" */).then(c => wrapFunctional(c.default || c))
export const NotificationsCreateGroup = () => import('../../components/notifications/create-group.vue' /* webpackChunkName: "components/notifications-create-group" */).then(c => wrapFunctional(c.default || c))
export const NotificationsDrawingDonation = () => import('../../components/notifications/drawing-donation.vue' /* webpackChunkName: "components/notifications-drawing-donation" */).then(c => wrapFunctional(c.default || c))
export const NotificationsDrawingSubmission = () => import('../../components/notifications/drawing-submission.vue' /* webpackChunkName: "components/notifications-drawing-submission" */).then(c => wrapFunctional(c.default || c))
export const NotificationsGeneralDonation = () => import('../../components/notifications/general-donation.vue' /* webpackChunkName: "components/notifications-general-donation" */).then(c => wrapFunctional(c.default || c))
export const NotificationsHelperDrawer = () => import('../../components/notifications/helper-drawer.vue' /* webpackChunkName: "components/notifications-helper-drawer" */).then(c => wrapFunctional(c.default || c))
export const NotificationsHelperGallery = () => import('../../components/notifications/helper-gallery.vue' /* webpackChunkName: "components/notifications-helper-gallery" */).then(c => wrapFunctional(c.default || c))
export const NotificationsHelperShare = () => import('../../components/notifications/helper-share.vue' /* webpackChunkName: "components/notifications-helper-share" */).then(c => wrapFunctional(c.default || c))
export const NotificationsHelperUpload = () => import('../../components/notifications/helper-upload.vue' /* webpackChunkName: "components/notifications-helper-upload" */).then(c => wrapFunctional(c.default || c))
export const NotificationsOrderDrawkit = () => import('../../components/notifications/order-drawkit.vue' /* webpackChunkName: "components/notifications-order-drawkit" */).then(c => wrapFunctional(c.default || c))
export const NotificationsResolver = () => import('../../components/notifications/resolver.js' /* webpackChunkName: "components/notifications-resolver" */).then(c => wrapFunctional(c.default || c))
export const ElementsButtonsClose = () => import('../../components/elements/buttons/close.vue' /* webpackChunkName: "components/elements-buttons-close" */).then(c => wrapFunctional(c.default || c))
export const ModalTemplatesEntry = () => import('../../components/modal/templates/entry.vue' /* webpackChunkName: "components/modal-templates-entry" */).then(c => wrapFunctional(c.default || c))
export const ModalTemplates = () => import('../../components/modal/templates/index.js' /* webpackChunkName: "components/modal-templates" */).then(c => wrapFunctional(c.default || c))
export const ModalTemplatesVideo = () => import('../../components/modal/templates/video.vue' /* webpackChunkName: "components/modal-templates-video" */).then(c => wrapFunctional(c.default || c))
export const ModulesToolbar = () => import('../../components/modules/toolbar/index.vue' /* webpackChunkName: "components/modules-toolbar" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
