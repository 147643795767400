
import { LocalStorage } from 'ttl-localstorage'
import iconClear from '@/components/icons/clear'
import theNotification from '@/components/notification'
import iconRotate from '@/components/icons/rotate'
import iconCamera from '@/components/icons/camera'

export default {
  components: {
    theNotification,
    iconRotate,
    iconClear,
    iconCamera
  },
  methods: {
    onClose () {
      // key will expire in 1 day
      LocalStorage.put('HELPER_UPLOAD', null)
    }
  }
}
